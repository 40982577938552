import React, {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { get } from 'utils/apis/insuranceLounge'

import { ActionTypes as AppActionTypes } from 'state/app/types'

import { getIsReduxLoggerEnabled } from 'state/app/selectors'

import { setIsReduxLoggerEnabled } from 'state/app/actions'

import {
  clearCurrentQuoteRequest,
  setCurrentQuoteRequest,
} from 'state/quotes/actions'

import Content, { Theme as ContentTheme } from 'components/base/Content'
import Button from 'components/base/Button'

import generatePrefillData from './generatePrefillData'

import {
  SubTitle,
  Title,
  Toggle,
  Wrapper,
} from './styles'

import {
  Addresses,
  Props,
} from './types'

const DevUtils: React.FC<Props> = ({
  isOpen: isOpenProp = false,
  ...props
}): React.ReactElement => {
  const dispatch = useDispatch()

  const isReduxLoggerEnabled = useSelector(getIsReduxLoggerEnabled)

  const [
    isOpen,
    setIsOpen,
  ] = useState(isOpenProp)
  const [
    isAddressesOpen,
    setIsAddressesOpen,
  ] = useState(false)
  const [
    addresses,
    setAddresses,
  ] = useState<Addresses>()

  useEffect(() => {
    const go = async () => {
      const resp = await get('/dev/quote-request-addresses')

      setAddresses((resp.success && resp.data) || undefined)
    }
    go()
  }, [])

  const toggleIsOpen = (): void => setIsOpen(!isOpen)

  return (
    <Wrapper {...props} className={(isOpen && 'open') || undefined}>
      <Toggle type='button' onClick={toggleIsOpen}>
        {(isOpen && '—') || '+'}
      </Toggle>
      {(isOpen && <Title>Dev Utilities</Title>) || undefined}

      <div className='container'>
        <Content theme={ContentTheme.paddedBelow}>
          <Button
            onClick={(): void => {
              dispatch(clearCurrentQuoteRequest())

              setIsOpen(false)
            }}
          >
            Clear current quote request
          </Button>
        </Content>
        <Content theme={ContentTheme.paddedBelow}>
          <SubTitle>Prefill current quote with valid Auto request data</SubTitle>
          {[
            'OR',
            'WA',
            'NV',
            'AZ',
            'CA',
          ].map(stateAbbr => (
            <React.Fragment key={stateAbbr}>
              <Button
                onClick={async (): Promise<void> => {
                  await dispatch(clearCurrentQuoteRequest())

                  dispatch(setCurrentQuoteRequest(await generatePrefillData('auto', 1, stateAbbr)))

                  setIsOpen(false)
                }}
              >
                {stateAbbr}
              </Button>
              {' '}
            </React.Fragment>
          ))}
        </Content>
        <Content theme={ContentTheme.paddedBelow}>
          <SubTitle>Prefill current quote with valid Home request data</SubTitle>
          {[
            'OR',
            'WA',
            'NV',
            'AZ',
            'CA',
          ].map(stateAbbr => (
            <React.Fragment key={stateAbbr}>
              <Button
                onClick={async (): Promise<void> => {
                  await dispatch(clearCurrentQuoteRequest())

                  dispatch(setCurrentQuoteRequest(await generatePrefillData('home', 1, stateAbbr)))

                  setIsOpen(false)
                }}
              >
                {stateAbbr}
              </Button>
              {' '}
            </React.Fragment>
          ))}
        </Content>
        <Content theme={ContentTheme.paddedBelow}>
          <Button
            onClick={(): void => {
              setIsAddressesOpen(!isAddressesOpen)
            }}
          >
            {isAddressesOpen ? 'Hide' : 'Show'} test addresses
          </Button>
        </Content>
        {(isAddressesOpen && (
          <Content theme={ContentTheme.paddedBelow}>
            <SubTitle>Test addresses:</SubTitle>
            {(!addresses && <p>No addresses found.</p>) || Object.values(addresses as unknown as Addresses || {}).map(address => (
              <ul key={address.stateAbbr}>
                <li>
                  {address.stateAbbr}: {address.completeAddress}
                  <button
                    type='button'
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(address.completeAddress)
                      setIsOpen(false)
                      setIsAddressesOpen(false)
                    }}
                  >
                    copy
                  </button>
                </li>
              </ul>
            ))}
          </Content>
        ))}
        <Content theme={ContentTheme.paddedBelow}>
          <Button
            onClick={(): void => {
              dispatch({ type: AppActionTypes.RESET_APP })

              setIsOpen(false)
            }}
          >
            Clear all redux data
          </Button>
        </Content>
        <Content theme={ContentTheme.paddedBelow}>
          <Button
            onClick={(): void => {
              dispatch(setIsReduxLoggerEnabled(!isReduxLoggerEnabled))

              setIsOpen(false)
            }}
          >
            Turn redux logging {isReduxLoggerEnabled ? 'OFF' : 'ON'}
          </Button>
        </Content>
      </div>
    </Wrapper>
  )
}

export default DevUtils
