import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 99999;

  .container {
    display: none;
  }

  &.open {
    background-color: #ddd;

    .container {
      display: block;
      clear: both;
      padding: 10px;
    }
  }
`

export const Toggle = styled.button`
  outline: none;
  float: right;
  border: none;
  background-color: #ddd;
  opacity: 0.25;
  min-width: 25px;
  text-align: center;
  cursor: pointer;
`

export const Title = styled.h1`
  margin: 10px;
  text-align: center;
`

export const SubTitle = styled.h3`
  margin: 0 0 10px 0;
`
